import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import { Grid, Button } from '@mui/material';

import ApiIcon from '@mui/icons-material/Api';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import InsightsIcon from '@mui/icons-material/Insights';
import HexagonIcon from '@mui/icons-material/Hexagon';
import FunctionsIcon from '@mui/icons-material/Functions';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';

import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import { blue, deepPurple, indigo } from '@mui/material/colors';
import { Helmet } from 'react-helmet-async';

import { useTheme } from '@mui/material/styles';

import DGLogo from '../components/DGLogo';
import BlockWithAvatar from '../components/blocks/blockWithAvatar';
import { Link  as RouterLink} from 'react-router-dom';

const LandingPage = () => {
    const theme = useTheme();
    return (
        <>
            <Helmet title='DriftGrid' />
            <Box pt={5} pb={5} sx={{ flexGrow: 1 }} bgcolor={theme.palette.grey[200]}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} alignSelf={"center"}>
                            <Typography variant='h2' component="h1">
                                Welcome to driftgrid.app
                            </Typography>
                            <Typography variant='h4' pb={5}>Unleash the Power of Geospatial Data!</Typography>
                            <RouterLink to="/examples/path">
                                <Button variant="contained">EXPLORE DRIFTGRID IN ACTION</Button>
                            </RouterLink>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
                            <DGLogo style={{ "width": "50%" }} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box pt={5} pb={5} sx={{ flexGrow: 1 }}>
                <Container>
                    <Grid container spacing={2} pt={5} pb={5}>
                        <Grid item xs={12}>
                            <Typography variant='overline'>Introduction</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Discover the World of Geospatial Data with DriftGrid</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>
                                Welcome to DriftGrid, the ultimate platform for unlocking the potential of geospatial data. Whether you're a developer, analyst, or data enthusiast, DriftGrid empowers you to harness the power of location-based information and transform it into actionable insights. Our unique approach of grouping data by hexagons allows you to organize, analyze, and visualize data related to specific geo points, regions, or paths. Get ready to explore the world around you like never before!
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box pt={5} pb={5} bgcolor={theme.palette.grey[200]}>
                <Container>
                    <Grid container spacing={2} pt={5} pb={5}>
                        <Grid item xs={12}>
                            <Typography variant='overline'>Why DriftGrid?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Unleash the Potential of Geospatial Data with DriftGrid</Typography>
                        </Grid>

                        <BlockWithAvatar
                            icon={<InsightsIcon />}
                            text="Harness the Power of Geospatial Data: DriftGrid empowers you to leverage the immense potential of geospatial data. Unlock valuable 
                        insights from location-based information and make data-driven decisions with confidence. Explore the world through a geospatial lens."
                        ></BlockWithAvatar>
                        <BlockWithAvatar
                            icon={<HexagonIcon />}
                            text="Hexagon-Based Grouping: DriftGrid's innovative approach of grouping data by hexagons offers a structured and intuitive way to organize and 
                        work with geospatial information. Hexagons provide a balanced and uniform grid, ensuring optimal data organization and analysis. 
                        Seamlessly navigate between macro and micro views of your data."
                        ></BlockWithAvatar>
                        <BlockWithAvatar
                            icon={<FunctionsIcon />}
                            text="Seamless Data Aggregation: DriftGrid enables you to aggregate data within specific hexagonal
                         regions, facilitating a deeper understanding of localized patterns and trends. Identify hotspots,
                          outliers, and correlations within geographically distinct areas effortlessly. Uncover insights that 
                          traditional aggregation methods might miss."
                        ></BlockWithAvatar>
                        <BlockWithAvatar
                            icon={<AutoGraphIcon />}
                            text="Advanced Analysis and Visualization: DriftGrid empowers you with advanced analysis and 
                        visualization tools for geospatial data. Explore relationships, detect spatial patterns,
                         and perform complex spatial queries with ease. Create visually compelling maps, charts, and graphs 
                         to communicate your findings effectively."
                        ></BlockWithAvatar>
                        <BlockWithAvatar
                            icon={<ApiIcon />}
                            text="Developer-Friendly APIs: DriftGrid provides easy-to-use APIs that enable seamless integration 
                        of geospatial data into your applications and workflows. Leverage our robust API infrastructure to build 
                        location-based features, geofencing capabilities, route optimization solutions, and more. Unlock the full potential of your geospatial data in your own applications.                    "
                        ></BlockWithAvatar>
                        <BlockWithAvatar
                            icon={<FlipToFrontIcon />}
                            text="Scalable and Cloud-based: DriftGrid is built on a scalable and cloud-based infrastructure,
                         ensuring reliable and efficient performance. Process and analyze large volumes of geospatial data
                          without worrying about infrastructure limitations. Scale up or down based on your needs, and focus 
                          on deriving insights instead of managing infrastructure."
                        ></BlockWithAvatar>
                    </Grid>
                </Container>
            </Box>
            <Box pt={5} pb={5}>
                <Container>
                    <Grid container spacing={2} pt={5} pb={5}>
                        <Grid item xs={12}>
                            <Typography variant='overline'>How DriftGrid Works</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Unlocking the Power of Geospatial Data Made Simple</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box p={3} borderRadius={3}  color={theme.palette.primary.contrastText} backgroundColor={indigo[500]}>
                                <Typography variant='body1'>
                                    Import Data: Seamlessly upload your geospatial data in various formats, including CSV, GeoJSON, Shapefiles, and more, to the DriftGrid platform. Our intuitive interface guides you through the process, ensuring a smooth data ingestion experience.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box p={3} borderRadius={3}  color={theme.palette.primary.contrastText} backgroundColor={indigo[400]}>
                                <Typography variant='body1'>
                                    Hexagon Grid Generation: DriftGrid generates a hexagonal grid based on the bounding box of your data. This hexagon grid serves as the foundation for organizing and grouping your geospatial information. The grid adapts dynamically to accommodate varying scales and resolutions.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box p={3} borderRadius={3}  color={theme.palette.primary.contrastText} backgroundColor={deepPurple[400]}>
                                <Typography variant='body1'>
                                    Data Grouping: The platform intelligently assigns data points to their corresponding hexagons, allowing you to explore patterns and relationships within specific geographical regions or along designated paths. Grouping data by hexagons provides a balanced and uniform representation, enhancing analysis accuracy.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box p={3} borderRadius={3}  color={theme.palette.primary.contrastText} backgroundColor={deepPurple[500]}>
                                <Typography variant='body1'>
                                    Advanced Filtering and Querying: DriftGrid equips you with powerful filtering and querying capabilities. Slice and dice your geospatial data based on attributes, spatial relationships, or custom conditions. Extract subsets of data relevant to your analysis, improving efficiency and focusing on specific areas of interest.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box p={3} borderRadius={3}  color={theme.palette.primary.contrastText} backgroundColor={blue[500]}>
                                <Typography variant='body1'>
                                    Spatial Analysis and Insights: Dive deep into your geospatial data using DriftGrid's suite of spatial analysis tools. Perform spatial joins, proximity analysis, spatial clustering, and more to uncover meaningful insights. Gain a deeper understanding of the spatial patterns and relationships within your data.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box p={3} borderRadius={3}  color={theme.palette.primary.contrastText} backgroundColor={blue[400]}>
                                <Typography variant='body1'>
                                Export and Integration: Once you've extracted valuable insights, export your findings for further analysis or seamlessly integrate them into your existing workflows and applications. DriftGrid supports data export in various formats, allowing you to share your geospatial insights with stakeholders or leverage them in other tools.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box pt={5} pb={5} sx={{ flexGrow: 1 }}>
                <Container>
                    <Grid container spacing={2} pt={5} pb={5}>
                        <Grid item xs={12}>
                            <Typography variant='overline'>Join DriftGrid Today!</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Start Exploring Geospatial Data Like Never Before</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' mb={2}>
                                Ready to unlock the power of geospatial data? Join DriftGrid today and embark on a journey of discovery and insights. Whether you're a professional or a hobbyist, our platform offers the tools and capabilities you need to leverage location-based information effectively. Sign up now and witness the transformative potential of DriftGrid!
                            </Typography>
                            <RouterLink to="/contact">
                                <Button variant="contained">CONTACTS</Button>
                            </RouterLink>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
};

export default LandingPage;