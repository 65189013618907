import { Avatar, Grid, Typography } from "@mui/material";

const BlockWithAvatar  = ({icon, text}) => {
    return (
        <Grid item xs={12} md={6}>
            <Grid container spacing={3} alignContent={"center"}>
                <Grid item xs={"auto"}>
                    <Avatar>
                        {icon}
                    </Avatar>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body1'>{text}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default BlockWithAvatar;