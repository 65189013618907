import { useMemo, useState } from 'react';
import { Backdrop } from "@mui/material";
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import { Alert, Grid, Snackbar, Typography } from '@mui/material';
// import Fab from '@mui/material/Fab';
import { Helmet } from 'react-helmet-async';

import { MapContainer } from 'react-leaflet/MapContainer';
import { Marker } from 'react-leaflet/Marker';
import { TileLayer } from 'react-leaflet/TileLayer';
import { FeatureGroup } from 'react-leaflet/FeatureGroup';
import { Polyline } from 'react-leaflet/Polyline';
import { useMapEvents } from 'react-leaflet/hooks';
import { EditControl } from "react-leaflet-draw";
import { useTheme } from '@mui/material/styles';


import { HexInfo, CellBoundary, CellPath } from '../components/DGApi';

import 'leaflet/dist/leaflet.css';
import "leaflet-draw/dist/leaflet.draw.css";


const ClickToIdPage = () => {

    const [map, setMap] = useState(null);
    const [lines, setLines] = useState([]);
    const [cells, setCells] = useState([]);
    const [point, setPoint] = useState(null);
    const [warn, setWarn] = useState("");

    const open = false;
    const theme = useTheme();

    const Lines = () => {
        if(!lines.length){
            return null;
        }
        console.log(lines);
        return (
            <>
            <Polyline pathOptions={{ color: theme.palette.secondary.main }} positions={lines} />
            </>
        )
    }
    const Polylines = () => {
        if(!cells.length){
            return null;
        }
        return (
            <>
             {cells.map(
                function(_cell, index)
                    {
                        return (
                            <Polyline 
                                key={index} 
                                pathOptions={{ color: theme.palette.primary.main, fillColor: theme.palette.primary.main}} 
                                positions={_cell.boundary} 
                            />
                        )
                    }
                )
            }
            </>
        )
    }

    const Pointer = () => {
        return point ? <Marker position={point} /> : null;
    }
    

    const DowningStreet = () => {
        const ds_points = [
            [
                51.50325706872397,
                -0.12792289194217688
            ],
            [
                51.50325526124237,
                -0.1279098251844175
                
            ],
            [
                51.5032353789409,
                -0.12743651817913815                
            ],
            [
                51.5032046517309,
                -0.12705177475430673
            ],
            [
                51.503174828242294,
                -0.1264347334125091                
            ],
            [
                51.50317135413587,
                -0.1262033788500787                
            ]        
        ];
        setPoint();
        setLines(ds_points);
        CellPath({"points":ds_points, "onSuccess": setCells});
        map.fitBounds(ds_points);
    };


    // API vents
    function GetHexByPoint(point) {
        if(!point){
            return false;
        }
        HexInfo({"position":point, "onSuccess":GetCellByHexId});
    };

    function GetCellByHexId(cell_reponse) {
        CellBoundary({"cell_id": cell_reponse.cell_id, "onSuccess": setCells})
    }


    
    function ClickOnMap() {
        const map = useMapEvents({
            click: (e) => {
                setPoint(e.latlng);
                setLines([]);
                // map.flyTo(e.latlng);
                map.setView(e.latlng, 18);
                GetHexByPoint(e.latlng); 

            }
        });
        return null
    }

    const handlePolyline = (e) => {
        const { layer, target } = e;
        const points = layer.getLatLngs().map(
            (coord) => {
                return [coord['lat'], coord['lng']]
            }
        );
        setLines([points]);
        CellPath({
            "points":points, 
            "onSuccess": (data) => {
                setCells(data.cells);
                setWarn(data.warning);
                target.fitBounds(points);
            }
        });
    };

    const displayMap = useMemo(
        () => (
            <MapContainer 
                center={[51.505, -0.09]} 
                zoom={13} 
                scrollWheelZoom={false}
                ref={setMap}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <FeatureGroup>
                    <EditControl 
                        position="topleft"
                        draw={{
                            polyline: true,
                            polygon: false,
                            circle: false,
                            rectangle: false,
                            marker: false,
                            circlemarker: false,
                          }}
                        onCreated={handlePolyline}
                    />
                </FeatureGroup>
                    <Pointer />
                    <Polylines />
                    <Lines/>
            </MapContainer>
        ),
        [cells, lines],
      )

    return (
        <>
        <Helmet title='Explore' />
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        ></Backdrop>
        <Box pt={3} sx={{ flexGrow: 1 }}>
        <Container>
    
            <Grid container spacing={2} pb={5}>
                <Grid item xs={12}>
                    <Typography variant='h5'>See DriftGrid in Action with Real-World Examples</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {displayMap}            
                </Grid>
                <Grid item xs={12} md={4}>
                    <>
                    <Box mb={3}>
                    This example shows how to convert lines representing routes into a sequence of hexagons. This feature is particularly 
                    useful in scenarios where you have a set of routes or paths and you want to represent them in a visually appealing 
                    and structured manner.
                    </Box>
                    <Typography variant='h5'>How to Use</Typography>
                    <Typography variant='body2'>1. Draw Your Route: Start by using the Leaflet Map Draw option to draw your desired 
                    route on the map. To do this, click on the "Polyline" button in the top-right corner of the map and start clicking on
                     the map to create the polyline. To finalize the route, simply double-click on the map. The drawn route will be displayed on the map.</Typography>
                    <Typography variant='body2'>2. Visualize Your Route: After finalizing the route, the application will automatically 
                    calculate and display the sequence of hexagons representing the drawn route. Each hexagon serves as a simplified representation 
                    of a segment of the route, making it easier to visualize complex paths.</Typography>
                    <Typography variant='body2'>3. Analyze and Understand: The converted hexagon path allows for easier analysis and understanding of the 
                    underlying patterns and connections in the route. You can use this feature for various applications, such as cartography, 
                    transportation planning, or network visualization.</Typography>
                    </>
                </Grid>
            </Grid>
        </Container>
        <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(warn)}
                autoHideDuration={10000}
                onClose={() => setWarn("")}
            >
                <Alert severity="warning" sx={{ width: '100%' }}>
                    {warn}  
                </Alert>
            </Snackbar>
    </Box>
    </>
    )
};

export default ClickToIdPage;