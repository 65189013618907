import { useTheme } from '@mui/material/styles';


const DGLogo = ({style}) => {
    const theme = useTheme();
    return(
        <svg className="dglogo" style={{...style, color: theme.palette.primary.main}} viewBox="0 0 128.8411822753877 147.84108059670595">
            <g transform="translate(-17.90235308848521, -6.664227173240391) scale(1.6116630723966114)" className="" fill="#000000">
                <path fill="currentColor" xmlns="http://www.w3.org/2000/svg" d="M51.58,5.867l-1-1.732L11.108,26.924v46.154l9.661,5.578L60.74,55.578V44.424l-7.66-4.423l4.062-2.345l7.661,4.423v15.846  L26.331,80.135l1,1.732l39.472-22.789V40.924l-7.66-4.423l4.062-2.345l7.66,4.422v22.846L32.394,83.635l1,1.732l39.471-22.789  V37.424l-7.66-4.423l4.062-2.345l7.66,4.422v29.846L38.456,87.135l1,1.732l39.471-22.789V33.924l-7.66-4.423l4.062-2.345  l7.661,4.422v36.846L44.518,90.635l1,1.732l39.472-22.789V30.424l-7.66-4.423l4.062-2.345l7.66,4.422v43.846L50.58,94.135l1,1.732  l39.471-22.789V26.924l-9.66-5.578L41.42,44.424v11.154l7.66,4.423l-4.062,2.345l-7.661-4.423V42.078l38.472-22.211l-1-1.732  L35.357,40.924v18.154l7.66,4.423l-4.062,2.345l-7.661-4.422V38.578l26.348-15.211v0l12.124-7l-1-1.732L29.295,37.424v25.154  l7.661,4.423l-4.062,2.345l-7.66-4.422V35.078l38.471-22.211l-1-1.732L23.233,33.924v32.154l7.66,4.423l-4.062,2.345l-7.661-4.422  V31.578L57.643,9.367l-1-1.732L17.171,30.424v39.154l7.66,4.423l-4.062,2.345l-7.661-4.422V28.078L51.58,5.867z M43.42,54.424  v-8.846l7.66-4.422l7.66,4.422v8.846l-7.66,4.422L43.42,54.424z"></path>
            </g>
        </svg>
    )
};

export default DGLogo;