import { Navigate, useRoutes } from 'react-router-dom';
import ContactUsPage from './pages/ContactUsPage';
import LandingPage  from './pages/LandingPage';
import ClickToIdPage from './pages/PolylineCoverage';

// layouts
import DashboardLayout from './layouts/dashboard';
import NavOnlyLayout from './layouts/navonly';
import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
// import UserPage from './pages/UserPage';
// import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/Privacy';
// import ProductsPage from './pages/ProductsPage';
// import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <NavOnlyLayout />,
      children: [
        { path: '/contact', element: <ContactUsPage />},
        { path: '/terms', element: <TermsPage />},
        { path: '/privacy', element: <PrivacyPage />},
        { path: '/', element: <LandingPage />, index: true},
      ]
    },
    {
      path: '/examples',
      element: <DashboardLayout />,
      children: [
        { path: 'path', element: <ClickToIdPage />},
    //     { element: <Navigate to="/dashboard/app" />, index: true },
      ],
    },
    // {
    //   path: 'login',
    //   element: <LoginPage />,
    // },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
