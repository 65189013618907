import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import DGLogo from '../../../components/DGLogo';
import { Link as RouterLink } from 'react-router-dom';

// const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));


export default function Header() {
    return (
      <StyledRoot>
        <StyledToolbar>
            <IconButton
                sx={{
                mr: 1,
                }}
            >
                <RouterLink to={"/"} >
                    <DGLogo style={{width: "45px"}}/>
                </RouterLink>
          </IconButton>
        </StyledToolbar>
      </StyledRoot>
    );
  }