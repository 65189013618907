import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import DGCookieConsensus from './components/CookieConsent';
import AnalyticsProvider from './firebase/context';


// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <AnalyticsProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <Router />
            <DGCookieConsensus />
          </ThemeProvider> 
        </BrowserRouter>
      </HelmetProvider>
    </AnalyticsProvider>
  );
}
