// component
import RouteIcon from '@mui/icons-material/Route';

// ----------------------------------------------------------------------



const navConfig = [
  {
    title: 'Polyline coverage',
    path: '/examples/path',
    icon: <RouteIcon />,
  }
];

export default navConfig;
