import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBs83x8s5NYM-8uD1jMs1G0352ATmfvD8I",
    authDomain: "driftgrid.firebaseapp.com",
    projectId: "driftgrid",
    storageBucket: "driftgrid.appspot.com",
    messagingSenderId: "855430958893",
    appId: "1:855430958893:web:943662ef6dc600eb7ccda4",
    measurementId: "G-E0VN31J7J8"
};

const app = () => {
    if(!firebaseConfig || !firebaseConfig.apiKey) {
        throw new Error('Missing Firebase configuration. Check src/firebase/config.js');
    }
    return initializeApp(firebaseConfig);
}

export default app;