import { createContext, useContext, useMemo } from "react";

import { getAnalytics } from "firebase/analytics";
import app from "./config";

const Context = createContext(null);

const AnalyticsProvider = ({ children }) => {
    const value = useMemo(() => {
       return {
              analytics: process.env.NODE_ENV === 'production' ? getAnalytics(app()) : null,
       }
    }, []);
    return <Context.Provider value={value}>{children}</Context.Provider>
};
export const useAnalytics = () => {
    return useContext(Context);
}

export default AnalyticsProvider;