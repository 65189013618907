import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import { Container, Typography } from '@mui/material';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

const Footer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  borderTop: `1px solid ${theme.palette.divider}`,
  // marginTop:  theme.spacing(7),
  padding: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function NavOnlyLayout() {
    return (
      <StyledRoot>
        <Header />
        <Main>
          <Outlet />
        </Main>
        <Footer>
          <Container>
            <Typography variant="body2" align="center" mb={3}>
              © driftgrid. 2023. All rights reserved
            </Typography>
            <Typography variant="caption" align="center">
              To enhance your experience, improve performance, and ensure your safety, we and our authorized service providers may utilize cookies when you visit or interact with our sites, services, or tools. These cookies store information and help us provide you with a better, faster, and more secure experience. Additionally, we may use cookies for marketing purposes. By continuing to use our website, you agree to our use of cookies as outlined in our Cookie Policy.
            </Typography>
          </Container>  
          </Footer>
      </StyledRoot>
    );
  }