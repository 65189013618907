import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import CookieConsent from "react-cookie-consent";



const DGCookieConsent = () => {
    const theme = useTheme();
    return  (
        <CookieConsent
            style={{ padding: "20px" }}
            buttonStyle={{ 
                padding: "10px 20px",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            }}
        >
            <Typography variant="body2" sx={{ color: "white" }} >
                This website uses cookies to enhance your browsing experience and provide personalized services. 
                By clicking "Accept" or by continuing to use this site, you consent to the use of cookies. 
            </Typography>
        </CookieConsent>
    )
};

export default DGCookieConsent;