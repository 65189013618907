import { useRef, useState } from 'react';
import { Link  as RouterLink} from 'react-router-dom';
import { Alert, Box, Button, Container, Divider, Grid, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import { SubmitContact } from '../components/DGApi';



const ContactUsPage = () => {
    const [ openSnackbar, setOpenSnackbar ] = useState(false);
    const [ textSnackbar, setTextSnackbar ] = useState("");
    const [ seviritySnackbar, setSeviritySnackbar ] = useState("info");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const recaptchaRef = useRef();

    const onError = ()  => {
        setSeviritySnackbar('error');
        setTextSnackbar('Message failed to send.');
        setOpenSnackbar(true);
        recaptchaRef.current.reset();
    };
    const onSuccess = () => {
        setSeviritySnackbar('success');
        setTextSnackbar('Message sent successfully.');
        setOpenSnackbar(true);
    };
    
    const onSubmit = async (data) => {
        const token = await recaptchaRef.current.executeAsync();
        const formData = {...data, token: token};
        await SubmitContact({contact: formData, onSuccess: onSuccess, onError: onError});
    }
    
    return (
        <>
        <Helmet title='Contact' />
        <Box>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{ mb: 2 }}>Contact Us</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            We value your feedback, questions, and inquiries. Please feel free to reach out to us using the contact 
                            information below. Our dedicated team is here to assist you and provide the information you need.<br/>
                            <br/>We strive to respond to all inquiries promptly. Whether you have a technical issue, a sales inquiry, or 
                            simply want to learn more about DriftGrid, we are here to help. We look forward to hearing from you and
                            assisting you on your geospatial data journey.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LciBPEmAAAAAF0cSnlwQsLemL0PN8n_avDbyDaX"
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Paper variant="outlined" sx={{ p: 2, width: '100%' }}>
                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            fullWidth 
                                            id="first-name" 
                                            label="First name" 
                                            variant="outlined"
                                            required
                                            {...register("first_name", { required: true, maxLength: 20 })}
                                            error={errors.first_name ? true : false} 
                                        >                                            
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            fullWidth 
                                            id="last-name" 
                                            label="Last name" 
                                            variant="outlined" 
                                            required
                                            {...register("last_name",  { required: true, maxLength: 20 })}
                                            error={errors.last_name ? true : false}

                                        >                                            
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth 
                                            id="email" 
                                            label="Email" 
                                            variant="outlined" 
                                            type="email" 
                                            required
                                            {...register("email",  { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                                            error={errors.email ? true : false}
                                        ></TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth 
                                            multiline 
                                            id="message" 
                                            label="Message" 
                                            variant="outlined" 
                                            type="text" 
                                            minRows={10} 
                                            required
                                            {...register("message",  { required: true, maxLength: 1000 })}
                                            error={errors.message ? true : false}
                                        ></TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button 
                                            fullWidth 
                                            variant="contained" 
                                            color="primary" 
                                            type="submit" 
                                            className="g-recaptcha"
                                            data-sitekey="6Lcj9vAmAAAAAMQuc1JRohu_rZjFmm1BIqF43LZB"
                                        >Submit
                                        </Button>
                                        <Divider sx={{ my: 3 }}></Divider>
                                        <Typography variant="body2" sx={{ textAlign: 'left' }}>By clicking on "Submit" you agree to our <RouterLink to="/terms">Terms and Conditions</RouterLink> and <RouterLink to="/privacy">Privacy Policy</RouterLink>.</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                        </form>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={10000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert severity={seviritySnackbar} sx={{ width: '100%' }}>
                    {textSnackbar}  
                </Alert>
            </Snackbar>
        </Box>
        </>
    )
};
export default ContactUsPage;