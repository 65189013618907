import { Box, Container, Grid, Typography } from "@mui/material";
import { Helmet } from 'react-helmet-async';

const TermsPage = () => {
    return(
        <>
        <Helmet title='Terms and Conditions' />
        <Box>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ mb: 2 }}>Terms and Conditions</Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Introduction</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            These Terms and Conditions ("Agreement") govern your use of the DriftGrid website 
                            (the "Website") and any services or products provided by DriftGrid ("Service"). By 
                            accessing or using the Website or Service, you agree to be bound by this Agreement. 
                            If you do not agree with these terms, please refrain from using the Website or Service.                                
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Intellectual Property</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            All intellectual property rights related to the Website and Service, including but not 
                            limited to copyrights, trademarks, and patents, belong to DriftGrid. You agree not to 
                            reproduce, distribute, or create derivative works based on any content from the Website 
                            or Service without obtaining explicit permission.                                
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>User Conduct</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            You agree to use the Website and Service responsibly and in compliance with all 
                            applicable laws and regulations. You will not engage in any unauthorized activities, 
                            including but not limited to hacking, data mining, or interfering with the 
                            functionality of the Website or Service.                                
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Disclaimer of Warranties</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            The Website and Service are provided on an "as is" and "as available" basis. DriftGrid 
                            does not make any warranties, express or implied, regarding the accuracy, reliability, 
                            or availability of the Website or Service. Your use of the Website and Service is at 
                            your own risk.                                
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Limitation of Liability</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            To the maximum extent permitted by law, DriftGrid and its affiliates, officers, 
                            employees, and agents will not be liable for any indirect, incidental, special, 
                            or consequential damages arising out of or in connection with your use of the 
                            Website or Service.                                
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Privacy Policy</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Your use of the Website and Service is also subject to our Privacy Policy, which 
                            describes how we collect, use, and disclose your personal information. By using the 
                            Website or Service, you consent to the collection and processing of your personal information 
                            as outlined in the Privacy Policy.    
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Governing Law and Jurisdiction</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            This Agreement will be governed by and interpreted in accordance with the laws of New York. 
                            Any disputes arising out of or in connection with this Agreement shall be resolved in the 
                            courts of New York.
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Amendments</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            DriftGrid reserves the right to modify or amend this Agreement at any time. Any changes will 
                            be effective upon posting the updated Agreement on the Website. Your continued use of the Website 
                            or Service after any modifications will signify your acceptance of the revised terms.
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Box>
        </>
    )
};

export default TermsPage;