import { Box, Container, Grid, Typography } from "@mui/material";
import { Helmet } from 'react-helmet-async';

const PrivacyPage = () => {
    return(
        <>
        <Helmet title='Privacy Policy' />
        <Box>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ mb: 2 }}>Privacy Policy</Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>Introduction</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            This Privacy Policy ("Policy") outlines how DriftGrid ("we," "us," or "our") collects, uses, 
                            discloses, and protects the personal information of users ("you" or "your") when you access
                             or use the DriftGrid website (the "Website") and any services or products provided by
                              DriftGrid (the "Service"). We are committed to respecting and protecting your privacy. By 
                              using the Website or Service, you consent to the practices described in this Policy.                                
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>1. Information We Collect</Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>We may collect the following types of information:</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            1.1 Personal Information: When you voluntarily provide it to us, we may collect personal 
                            information such as your name, email address, phone number, and other contact details.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            1.2 Usage Information: We may collect information about your use of the Website and Service, 
                            including your IP address, device information, browser type, operating system, and usage data.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            1.3 Cookies and Tracking Technologies: We may use cookies, web beacons, and similar technologies 
                            to collect information about your browsing activities and preferences on the Website. This information 
                            helps us improve the functionality and performance of the Website.
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 2 }}>2. How We Use Your Information</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>We may use your information for the following purposes:
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            2.1 Provide and Improve Service: We use your information to deliver and improve the functionality 
                            and features of the Service, personalize your experience, and respond to your inquiries or requests.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            2.2 Communication: We may send you service-related communications, updates, and promotional offers. 
                            You can opt-out of marketing communications at any time.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            2.3 Analytics and Research: We analyze user behavior and preferences to enhance and optimize our 
                            Service, perform research, and generate insights.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            2.4 Compliance and Protection: We may use your information to comply with applicable laws, enforce 
                            our terms and conditions, protect our rights, and ensure the safety and security of the Website and Service.
                        </Typography>

                        <Typography variant="h6" sx={{ mb: 2 }}>3. Information Sharing and Disclosure</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            3.1 Service Providers: We may engage trusted third-party service providers to assist us in delivering the Service and perform related functions on our behalf. These service providers are
                            bound by confidentiality agreements and are prohibited from using your information for any other purpose.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            3.2 Legal Compliance: We may disclose your information when required by law, governmental authority, 
                            or to protect our legal rights or the safety of users.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            3.3 Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may 
                            be transferred to the acquiring entity or successor.
                        </Typography>

                        <Typography variant="h6" sx={{ mb: 2 }}>4. Data Security</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>We take appropriate technical and organizational measures to 
                        safeguard your information and protect it from unauthorized access, loss, misuse, alteration, or disclosure. 
                        However, no data transmission or storage system can be guaranteed to be 100% secure. You are responsible 
                        for maintaining the confidentiality of any account credentials and passwords.
                        </Typography>
                        
                        <Typography variant="h6" sx={{ mb: 2 }}>5. Your Rights and Choices</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            You have certain rights and choices regarding your 
                            personal information:
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            5.1 Opt-Out: You can opt-out of receiving marketing communications by following the unsubscribe instructions 
                            provided in the communication or by contacting us.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            5.2 Cookies: You can set your browser to refuse cookies or alert you when cookies are being sent.
                             However, disabling cookies may impact the functionality of the Website.
                        </Typography>

                        <Typography variant="h6" sx={{ mb: 2 }}>6. Children's Privacy</Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            The Website and Service are not intended for individuals under the age of 13. We do not knowingly collect or solicit personal information 
                            from children. If we become aware that we have collected personal information from a child without parental consent
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        </>
    )
};

export default PrivacyPage;