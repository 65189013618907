import axios from "axios";

const api_endpoint = process.env.REACT_APP_API_URL ?? 'https://api.driftgrid.app';


export const CellBoundary = ({cell_id, onSuccess}) => {
    axios.get(`${api_endpoint}/cell_boundary/${cell_id}/`)
    .then(res => {onSuccess([res.data,])})
    .catch(error => {onSuccess({})})
}

export const HexInfo = ({position, onSuccess}) => {
    axios.get(`${api_endpoint}/cell/${position.lat}/${position.lng}/13/`)
    .then(res => {onSuccess(res.data)})
    .catch(error => {onSuccess({})})
}

export const CellPath = ({points, onSuccess}) => {
    axios.post(`${api_endpoint}/multi_line_to_cells/`, {multiline: points})
    .then(res => {onSuccess(res.data)})
    .catch(error => {onSuccess({"cells": [], "warning": error})})
}

export const SubmitContact = async ({contact, onSuccess, onError}) => {
    axios.post(`${api_endpoint}/contact/`, contact)
    .then(res => {onSuccess()})
    .catch(error => {onError({})})
}
